/* eslint-disable no-underscore-dangle */
import {isString, isFunction, filter, map, toArray, each} from './utils'
import {fetchTokenNumbers} from './Mapi'
import DomReplacer from './DomReplacer'
import {getFromName} from './PhoneFormatters'
import * as Env from './Env'
import EventEmitter from './EventEmitter'

// Used to actually request rotated numbers from MAPI (if necessary), and
// perform the phone number replacement
export default class RotationReplacer {
  constructor() {
    this.events = new EventEmitter()
    this._watchInterval = false
  }

  /**
   * @param conf RotationConfig
   */
  setConfig(conf) {
    this._conf = conf
    this._replacers = this._buildReplacers()
  }

  _buildReplacers() {
    return filter(
      map(toArray(this._conf.replacementConfig()), conf => {
        if (isString(conf)) {
          return this._buildSimpleReplacer({selectors: [conf]})
        }

        return this._buildSimpleReplacer(conf)
      }),
    )
  }

  _buildSimpleReplacer(conf) {
    const formatter = isFunction(conf.formatter) ? conf.formatter : getFromName(conf.formatter)

    const repl = new DomReplacer(
      conf.selectors,
      conf.token || this._conf.defaultToken(),
      formatter,
      conf.filters || [],
      conf.numbersToReplace || [],
    )

    repl.setAllowedTokens(conf.allowedTokens || [])
    return repl
  }

  replaceRotated() {
    if (!this._conf.promo()) {
      return Promise.reject(new Error('no promo code for rotation'))
    }

    const allTokens = {}

    /** @var {DomReplacer} repl */
    each(this._replacers, repl => {
      const foundTokens = repl.getTokensToReplace()
      Env.info('Found tokens from replacer', foundTokens, repl)
      each(foundTokens, t => {
        allTokens[t] = true
      })
    })

    return fetchTokenNumbers(Object.keys(allTokens), this._conf)
      .then(response => {
        each(this._replacers, r => r.performReplacement(response.tokens))
        return response
      })
      .catch(err => {
        Env.error(err)
      })
  }

  watchForReplacementIfConfigured() {
    if (!this._conf.get('watchForReplacement')) {
      return
    }

    this.watchForReplacement(this._conf.get('watchInterval', 2000))
  }

  watchForReplacement(interval = 2000) {
    if (this._watchInterval) {
      clearInterval(this._watchInterval)
    }

    this._watchInterval = setInterval(() => this.replaceRotated(), interval)
  }
}
